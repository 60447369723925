import { render, staticRenderFns } from "./integraldetail.vue?vue&type=template&id=0307f8b4&scoped=true"
import script from "./integraldetail.vue?vue&type=script&lang=js"
export * from "./integraldetail.vue?vue&type=script&lang=js"
import style0 from "./integraldetail.vue?vue&type=style&index=0&id=0307f8b4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0307f8b4",
  null
  
)

component.options.__file = "integraldetail.vue"
export default component.exports